<template>
  <div>
    <vs-card>
      <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-table max-items="10" pagination search :data="netEnquiryData">
            <template slot="header">
              <vs-row class="gap" style="bottom: 10px; position: relative">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="6"
                  ><h3>Net Enquiry Source</h3></vs-col
                >
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="center"
                  vs-w="6"
                  ><vs-button
                    radius
                    color="primary"
                    icon="add"
                    type="gradient"
                    @click="addNetEnquiry()"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </template>
            <template slot="thead">
              <vs-th sort-key="email">Source Name </vs-th>
              <vs-th sort-key="email">Priority</vs-th>
              <vs-th sort-key="email"> Action </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].source }}
                </vs-td>
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].sourceName }}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  <vs-row>
                    <vs-col vs-type="flex" vs-w="12">
                      <vs-button
                        radius
                        color="success"
                        type="gradient"
                        icon="create"
                        @click="editNetEnquiry(data[indextr])"
                      ></vs-button
                      >&nbsp;&nbsp;
                      <vs-button
                        radius
                        color="danger"
                        type="gradient"
                        icon="delete"
                        @click="deleteNetEnquiry(data[indextr])"
                      ></vs-button>
                    </vs-col>
                  </vs-row>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </vs-card>
    <vs-popup title="Are You Sure ?" :active.sync="deleteNetEnquiryPopup">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="7"
          >You Can't Undo the Action you will perform
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
          <vs-button
            type="gradient"
            size="small"
            style="font-size: 14px"
            color="success"
            @click="deleteNetEnquiryName(netEnquiryId)"
            >Delete</vs-button
          >&nbsp;&nbsp;
          <vs-button
            type="gradient"
            size="small"
            style="font-size: 14px"
            color="danger"
            @click="closePopup()"
            >Cancel</vs-button
          ></vs-col
        >
      </vs-row>
    </vs-popup>
    <vs-popup title="Edit Course" :active.sync="editNetEnquiryPopup">
      <vs-input
        class="inputx"
        placeholder="Name"
        v-model="netEnquiryName"
      /><br />
      <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"
        >Priority:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
        <v-select
          :options="priority"
          v-model="selectPriority"
           style="width: 240px; z-index: 1000"
        ></v-select></vs-col
      ></vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-button
            type="gradient"
            size="small"
            style="font-size: 14px"
            color="warning"
            @click="updateNetEnquiryName(netEnquiryId)"
            >Update</vs-button
          >&nbsp;&nbsp;
          <vs-button
            type="gradient"
            size="small"
            style="font-size: 14px"
            color="danger"
            @click="closePopup()"
            >Cancel</vs-button
          ></vs-col
        >
      </vs-row>
    </vs-popup>
    <vs-popup title="Add Net Enquiry Source" :active.sync="addNetEnquiryPopup">
      <vs-input
        class="inputx"
        placeholder="Source Name"
        v-model="netEnquiryName"
      /><br />
      <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"
        >Priority:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
        <v-select
          :options="priority"
          v-model="selectPriority"
          style="width: 240px; z-index: 1000"
        ></v-select></vs-col
      ></vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-button
            type="gradient"
            size="small"
            style="font-size: 14px"
            color="success"
            @click="addNetEnquiryName()"
            >Add</vs-button
          >&nbsp;&nbsp;
          <vs-button
            type="gradient"
            size="small"
            style="font-size: 14px"
            color="danger"
            @click="closePopup()"
            >Cancel</vs-button
          ></vs-col
        >
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import vSelect from "vue-select";
export default {
  data() {
    return {
      netEnquiryData: [],
      addNetEnquiryPopup: false,
      netEnquiryId: null,
      netEnquiryName: "",
      priority: [
        {
          id: 1,
          label: "P1",
        },
        {
          id: 2,
          label: "P2",
        },
        {
          id: 3,
          label: "P3",
        },
      ],
      selectPriority: null,
      editNetEnquiryPopup: false,
      deleteNetEnquiryPopup: false,
      selectPriorityId: "",
    };
  },
  components: {
    "v-select": vSelect,
  },
  watch: {
    selectPriority(val) {
      if (val.label === "P1") {
        this.selectPriority = 1;
      } else if (val.label === "P2") {
        this.selectPriority = 2;
      } else if (val.label === "P3") {
        this.selectPriority = 3;
      }
    },
  },
  mounted() {
    this.getNetEnquiryData();
  },
  methods: {
    addNetEnquiry() {
      this.addNetEnquiryPopup = true;
      this.netEnquiryName = "";
      this.selectPriority = "";
    },
    getNetEnquiryData() {
      let url = `${constants.MILES_CM_BACK}netEnquirySource`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // this.selectSpocCity(response);
          console.log("get net Enquiry data", response);
          this.netEnquiryData = response.data.data;
          this.netEnquiryData.forEach(element => {
            if(element.bucket === 1){
              element.sourceName= 'P1'
            }else if(element.bucket === 2){
              element.sourceName= 'P2'
            }else if(element.bucket === 3){
              element.sourceName= 'P3'
            }
          });
         
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
   
    addNetEnquiryName() {
      let url = `${constants.MILES_CM_BACK}netEnquirySource`;
      let obj = {
        source: this.netEnquiryName,
        bucket: this.selectPriority,
      };
      console.log(obj);
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("response add course", response);
          this.netEnquiryName = obj.source;
          this.selectPriority = obj.bucket;
          this.getNetEnquiryData();
          this.netEnquiryName = "";
          // this.priority = "";
          this.$vs.notify({
            title: "Created",
            text: "",
            color: "success",
          });
          this.addNetEnquiryPopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    updateNetEnquiryName(id) {
      let obj = {
        source: this.netEnquiryName,
        bucket: this.selectPriority,
      };
      let url = `${constants.MILES_CM_BACK}netEnquirySource/${id}`;
      axios
        .put(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("response", response);
          this.editNetEnquiryPopup = false;
          this.getNetEnquiryData();
          this.netEnquiryId = null;
          this.netEnquiryName = "";
          this.selectPriority = "";
          this.$vs.notify({
            title: "Updated",
            text: "",
            color: "success",
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    editNetEnquiry(val) {
      this.netEnquiryId = val.id;
      this.editNetEnquiryPopup = true;
      this.netEnquiryName = val.source;
      this.selectPriority = val.bucket;
    },
    deleteNetEnquiry(val) {
      this.netEnquiryId = val.id;
      this.deleteNetEnquiryPopup = true;
    },
    deleteNetEnquiryName(id) {
      let url = `${constants.MILES_CM_BACK}netEnquirySource/${id}`;

      axios
        .delete(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("This Course has been Deleted", response);
          this.getNetEnquiryData();
          this.$vs.notify({
            title: "Deleted",
            text: "",
            color: "success",
          });
          this.deleteNetEnquiryPopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    closePopup() {
      this.editNetEnquiryPopup = false;
      this.deleteNetEnquiryPopup = false;
      this.addNetEnquiryPopup = false;
    },
  },
};
</script>

<style>
</style>