<template>
  <div>
      <enquirySource/>
  </div>
</template>

<script>
import enquirySource from "../../src/components/netEnquiry/enquirySource"
export default {
    components:{
        enquirySource
    }
}
</script>

<style>
</style>